<template>
  <b-modal :active.sync="isModalActive" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Confirm action</p>
      </header>
      <section class="modal-card-body">
        <div v-if="isDeleted">
          <p>{{ $getTranslation('form.destroy.message') }} <b>{{ trashObjectName }}</b></p>
          <p>{{ $getTranslation('form.destroy.action') }}</p>
        </div>
        <div v-else>
          <p>{{ $getTranslation('form.delete.message') }} <b>{{ trashObjectName }} ?</b></p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" @click="cancel">Cancel</button>
        <button class="button is-danger" @click="confirm">Delete</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'app-modal-box',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    trashObjectName: {
      type: String,
      default: null
    },
    trashObjectDeleted: {
      // can be null or date string
      type: String,
      default: null
    }
  },
  data () {
    return {
      isModalActive: false,
      isDeleted: false
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      console.log('to confirmDelete')
      this.$emit('confirm')
    }
  },
  watch: {
    isActive (newValue) {
      // console.log('active: ', this.trashObjectDeleted)
      this.isModalActive = newValue
      if(this.trashObjectDeleted === null) {
        // console.log('should be deleted')
        this.isDeleted = false
      } else {
        // console.log('should be destroyed')
        this.isDeleted = true
      }
    },
    isModalActive (newValue) {
      // console.log('is modal active', this.trashObjectDeleted)
      if (!newValue) {
        this.cancel()
      }
    }
  },
  created() {
    // console.log('Deleted: ', this.trashObjectDeleted)
  }
}
</script>

import merge from 'lodash/merge'

// import {} from '../../mutation-types'

import {
} from '../../../api-routes'

/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  dashboardUi: {
    noActiveSurveysWindow: {
      closed: false
    }
  },
  surveysUi: {
    surveyOverview: {
      openedTab: 0
    }
  },
  usersUi: {},
}

/**
 * Mutations
 * the only way to change the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  setUiState(state, payload) {
    // merge state from payload into state
    state[payload.uiName] = merge(state[payload.uiName], payload.mergeData);
  }
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  dashboardUi: state => state.dashboardUi,
  surveysUi: state => state.surveysUi,
  usersUi: state => state.usersUi,
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  setUiState({commit}, payload) {
    commit("setUiState", payload);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div>
      <router-view></router-view>
      <nav-bar/>
      <footer-bar/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NavBar from '@/components/layout/NavBar'
import FooterBar from '@/components/layout/FooterBar'

export default {
  name: 'app-content',
  components: {
    FooterBar,
    NavBar,
  },

  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedUser',
      'loading',
    ]),
  },
}

</script>
<style scoped>

</style>

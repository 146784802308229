import axios from 'axios'

import {
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
} from '../../mutation-types'


/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  //
}

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  //
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  //
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  /**
   * fetch all survey-session items
   * try to modify it so to use fetchItem(s)
   */
  getSurveySessions({ commit, getters }, payload) {
    return axios
      .get(payload)
      .then(response => {
        //commit(SET_LOADING, false)
        let res = response.data.data;
        if (res === undefined || res.length === 0) {
          //
        } else {
          return res;
        }
      })
      .catch(error => {
        console.log("error fetching surveySessionsOverTime: ", error);
        //commit(SET_LOADING, false)
      });
  },

}

export default {
  state,
  getters,
  mutations,
  actions
}

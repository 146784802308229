import Languages from '@/translations';
import {
  SET_LANGUAGE,
} from '@/store/mutation-types'
// Vue.use(VueLocalStorage)
const supportedLanguages = Object.getOwnPropertyNames(Languages)

// console.log('languages: ', Languages)
const state = {
  language: localStorage.getItem('language'),
  defaultLanguage: 'en',
  languages: []
}

const mutations = {
  [SET_LANGUAGE](state, lang) {
    localStorage.setItem('language', lang)
    state.language = lang
  },
}

const actions = {
  setLanguage({ commit }, languages) {
    // console.log('lang: ',this.state.languageStore.defaultLanguage)
    let language = ''
    if (typeof languages === 'string') {
      language = languages
      // console.log('lang? ', languages, supportedLanguages, this.state.languageStore.defaultLanguage)
      if(!supportedLanguages.includes(language)) {
        language = this.state.languageStore.defaultLanguage
      }
    } else {
      language = supportedLanguages.find(lang => languages.find(l => (l.split(new RegExp(lang, 'gi')).length - 1 > 0 ? lang : null)))
      // read property 'defaultLanguage' of undefined
      // language = languages.find(lang => supportedLanguages.find(l => (l.split(new RegExp(lang, 'gi')).length - 1 > 0 ? lang : null)))
      // console.log('lang? ', language, supportedLanguages, this.state)
      if(!supportedLanguages.includes(language)) {
        language = this.state.languageStore.defaultLanguage
      }
    }
    commit(SET_LANGUAGE, language)
  },
}
export default {
  // namespaced: true,
  state,
  mutations,
  actions
}


import { Ability, AbilityBuilder } from '@casl/ability'

// https://gist.github.com/stalniy/5adcccf8117177078ada0454e6a6d300
export default function defineAbilitiesFor(userPermissions) {
  const { can, cannot, rules } = new AbilityBuilder();
  // console.log(userPermissions)
  if (userPermissions === null) {
    console.log('null permissions')
    return new Ability(rules)
  }
  if (typeof userPermissions === 'undefined') {
    console.log('undefined permissions')
    return new Ability(rules)
  }

  // Read the permissions out of the returned user data and update the CASL Ability instance
  const permissions = userPermissions.map(p => {
    const pair = p.split(/-(.+)/)
    // console.log('pair: ', pair)
    let obj = {
      action: pair[1],
      subject: pair[0]
    }
    // console.log('obj: ', obj)
    return obj
  })
  for (const p of permissions) {
    can(p.action, p.subject)
  }
  // console.log('ability rules: ', new Ability(rules))
  return new Ability(rules)
}



// console.log('NODE_ENV: ', process.env.NODE_ENV, 'location: ', location.origin, 'url: ', url);

export const baseTitle = process.env.VUE_APP_TITLE;
export const baseUrl = process.env.VUE_APP_API_ROOT;
export const apiUrl = process.env.VUE_APP_API_ROOT + '/api/';
export const adminUrl = process.env.VUE_APP_API_ROOT + '/admin/';

// ENDPOINTS
export const ApiDashboard  = apiUrl + 'dashboard';

// export const ApiTestUser  = apiUrl + 'getTestUser';
export const ApiTestUser  = apiUrl + 'test/testAction?email=tereza.simcic@gmail.com';

// Users
export const ApiUser  = apiUrl + 'user';
export const ApiUsers  = apiUrl + 'users';

// Auth
export const ApiLogin  = apiUrl + 'login';
export const ApiLogout  = apiUrl + 'logout';
export const ApiRegister  = apiUrl + 'register';
export const ApiForgotPassword  = apiUrl + 'password/email';
export const ApiResetPassword  = apiUrl + 'password/reset';

// LogActivities
export const ApiLogActivities  = adminUrl + 'activities';

// Management
export const ApiRoles  = adminUrl + 'roles';
export const ApiPermissions  = adminUrl + 'permissions';

// Organizations
export const ApiOrganizations  = apiUrl + 'organizations';
export const ApiOrganization  = apiUrl + 'organization';
// export const ApiOrganizationNames = ApiOrganizations + '/names';
// export const ApiDivisionNames = ApiOrganizations + '/divisions/names';

// Reporting
export const ApiReporting  = apiUrl + 'reporting';
// Surveys
export const ApiSurveys  = apiUrl + 'surveys';
export const ApiSurvey  = apiUrl + 'survey';

// Endpoints - redirects
export const  loggedInPage = 'dashboard';
export const  loginPage = 'login';
export const unauthorized = 'dashboard'   // TODO: change this to a sensible default!

// Stats and key figures for dashboard
export const ApiStats = apiUrl + 'stats';
export const ApiProjects = apiUrl + 'projects';
// Provide project details on accept/reject form (@see ResetPassword)
// with token (consists of token if user has been invited inside
// the system, or with media+token if the user has been invited from
// social media (link, fb, tw))
export const ApiProjectDetails = apiUrl + 'projectDetails/';

// export const ApiStatsOverview = apiUrl + 'stats/overview';

// Scales
export const ApiScales  = apiUrl + 'scales';

// Invitations
export const ApiInvitations  = apiUrl + 'invitations';
// export const ApiAcceptRejectInvite  = apiUrl + 'acceptRejectInvite';
export const ApiAcceptInvite  = apiUrl + 'acceptInvite';
export const ApiRejectInvite  = apiUrl + 'rejectInvite';

// Messaging - Notifications
export const ApiMessaging  = apiUrl + 'messages';
// slug of survey instance should be appended
export const ApiDownloadInstanceCsv  = apiUrl + 'projects/downloadCsv';

// Pusher / Echo
export const pusherConfig = {
    pusher_id: process.env.VUE_APP_PUSHER_APP_ID,
    pusher_key: process.env.VUE_APP_PUSHER_APP_KEY,
    pusher_secret: process.env.VUE_APP_PUSHER_APP_SECRET,
    pusher_cluster: 'eu',
    echoAuthEndpoint: baseUrl + "/broadcasting/auth",
}
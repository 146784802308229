// for shared getters, mutations, actions ...
import {
  // SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR
} from '../../mutation-types'

const state = {
  error: null
}

const mutations = {
  [SET_ERROR] (state, payload) {
    state.error = payload
  },
  [CLEAR_ERROR] (state) {
    state.error = null
  }
}

const getters = {
  error: state => state.error
}

const actions = {
  clearError ({ commit }, field) {
    if (field !== null || field !== undefined) {
      // delete this.error[field];

      // return;
    }
    commit(CLEAR_ERROR)
  },

  setError ({ commit }, payload) {
    // console.log('set error: ', payload)
    commit(SET_ERROR, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <div>
      <img
        src="@/assets/metrify-logo-174x50.png"
        width="150px"
        style="margin-top: 5px;"
      />
    </div>
    <aside-tools :is-main-menu="true">
      <div slot="label">
        <p>{{ isLoggedUser.userProperName }} ({{ isLoggedUser.roles }})</p>

        <a
          class="menu-item"
          href="/profile"
          :title="$getTranslation('menu.profile')"
        >
          <b-icon icon="account" />
        </a>
        <a
          class="menu-item"
          href="/settings"
          :title="$getTranslation('menu.settings')"
        >
          <b-icon icon="settings" style="pointer-events: none; opacity: .65;" />
        </a>
        <!-- <a class="menu-item" href="/messages">
          <b-icon icon="email" />
        </a> -->
        <app-pusher-listener
          :userId="isLoggedUser.userId"
        ></app-pusher-listener>
        <app-unread-messages></app-unread-messages>
        <a class="menu-item" href="/help" :title="$getTranslation('menu.help')">
          <b-icon icon="help-circle-outline" style="cursor: help" />
        </a>
        <a
          class="menu-item is-desktop-icon-only"
          title="Log out"
          @click="logout"
          :title="$getTranslation('menu.logout')"
        >
          <b-icon icon="logout" class="has-text-warning-dark" />
        </a>
      </div>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <div v-if="menuGroup.collpsable">
          <b-collapse :open="false" aria-id="contentIdForA11y1">
            <template #trigger="props">
              <p
                class="menu-label"
                :key="index"
                aria-controls="contentIdForA11y1"
                v-if="isAccessible(menuGroup.gate, menuGroup.groupName, true)"
              >
                {{ menuGroup.groupName }}
                <b-icon :icon="props.open ? 'menu-down' : 'menu-right'">
                </b-icon>
              </p>
            </template>
            <ul class="menu-list">
              <li v-for="(item, index) in menuGroup.items">
                <component
                  v-if="isAccessible(item.gate, item.label)"
                  :is="componentIs(item)"
                  :to="itemTo(item)"
                  :href="itemHref(item)"
                  exact-active-class="is-active"
                  :class="{ 'has-icon': !!item.icon }"
                  :key="index"
                >
                  <b-icon
                    v-if="item.icon"
                    :icon="item.icon"
                    :class="{ 'has-update-mark': item.updateMark }"
                    custom-size="default"
                  />
                  <span
                    v-if="item.label"
                    :class="{ 'menu-item-label': !!item.icon }"
                    >{{ item.label }}</span
                  >
                </component>
              </li>
            </ul>
          </b-collapse>
        </div>
        <div v-else>
          <p class="menu-label" :key="index">
            {{ menuGroup.groupName }}
          </p>
          <ul class="menu-list">
            <li v-for="(item, index) in menuGroup.items">
              <component
                v-if="isAccessible(item.gate, item.label)"
                :is="componentIs(item)"
                :to="itemTo(item)"
                :href="itemHref(item)"
                exact-active-class="is-active"
                :class="{ 'has-icon': !!item.icon }"
                :key="index"
              >
                <b-icon
                  v-if="item.icon"
                  :icon="item.icon"
                  :class="{ 'has-update-mark': item.updateMark }"
                  custom-size="default"
                />
                <span
                  v-if="item.label"
                  :class="{ 'menu-item-label': !!item.icon }"
                  >{{ item.label }}</span
                >
              </component>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AsideTools from '@/components/layout/AsideTools'
import NotificationsListener from '../pusher/NotificationsListener';
import UnreadMessages from '../pusher/UnreadMessages';

export default {
  name: 'AsideMenu',
  components: {
    AsideTools,
    'app-unread-messages': UnreadMessages,
    'app-pusher-listener': NotificationsListener,
  },
  data () {
    return {
      menu: [
        {
          groupName: this.$getTranslation('menu.general'),
          collpsable: false,
          items: [
            {
              to: '/dashboard',
              icon: 'desktop-mac',
              label: this.$getTranslation('menu.dashboard')
            },
            {
              to: '/messages',
              icon: 'email',
              label: this.$getTranslation('menu.inbox')
            }
          ],
        },
        {
          groupName: this.$getTranslation('menu.metrify'),
          collpsable: true,
          gate: ['survinst-list', 'report-list', 'user-list'],
          items: [
            {
              to: '/surveys',
              label: this.$getTranslation('menu.surveys'),
              icon: 'format-list-checkbox',
              gate: ['survinst-list'],
            },
            {
              to: '/reporting',
              label: this.$getTranslation('menu.reporting'),
              icon: 'notebook-outline',
              gate: ['report-list'],
            },
            {
              to: '/users',
              label: this.$getTranslation('menu.users'),
              icon: 'account',
              gate: ['user-list'],
            },
            {
              to: '/participants',
              label: this.$getTranslation('menu.participants'),
              icon: 'account',
              gate: ['user-list'],
            },
            /*{
              to: '/collaborators',
              label: this.$getTranslation('menu.collaborators'),
              icon: 'account',
              gate: ['user-list'],
            },*/
          ],
        },
        {
          groupName: this.$getTranslation('menu.scales'),
          collpsable: true,
          gate: ['scale-list'],
          items: [
            {
              to: '/measurement',
              label: this.$getTranslation('menu.measurement'),
              icon: 'ruler',
              gate: ['scale-list'],
            },
            {
             to: '/response',
             label: this.$getTranslation('menu.response'),
             icon: 'crosshairs-question',
             gate: ['scale-list'],
           }
          ],
        },
        {
          groupName: this.$getTranslation('menu.invitations'),
          collpsable: true,
          items: [
            {
              to: '/invitations',
              label: this.$getTranslation('menu.list-invitations'),
              icon: 'mail',
              gate: ['invitation-list'],
            },
            {
              to: '/invitations/create',
              label: this.$getTranslation('menu.send-invitations'),
              icon: 'plus'
            },
          ],
        },
        {
          groupName: this.$getTranslation('menu.administration'),
          collpsable: true,
          gate: ['admin'],
          items: [
            {
              to: '/admin/activities',
              label: this.$getTranslation('menu.logActivities'),
              icon: 'buffer',
              gate: ['admin'],
            },
            {
              to: '/roles',
              label: this.$getTranslation('menu.roles'),
              icon: 'lock',
              gate: ['admin'],
            },
            {
              to: '/permissions',
              label: this.$getTranslation('menu.permissions'),
              icon: 'lock',
              gate: ['admin'],
            }
          ],
        }
      ]
    }
  },

  computed: {
    ...mapState([
      'isAsideVisible'
    ]),

    ...mapGetters([
      'isLoggedUser'
    ]),
  },

  methods: {
    isAccessible (gates, name, group=false) {
      let ok = []
      if(typeof gates !== 'undefined') {
        gates.forEach(gate => {
          if (gate !== 'admin') {
            if(this.isLoggedUser.permissions.includes(gate)) {
              ok.push(1)
            }
          } else {
            if(this.isLoggedUser.roles.includes(gate)) {
              ok.push(1)
            }
          }
        })
      } else {
        ok.push(1)
      }
      if (group) {
        if (ok.length) {
          return true
        }
      } else {
        if(ok.length) {
          return true
        }
        return false
      }
    },

    componentIs (item) {
      return item.to ? 'router-link' : 'a'
    },

    itemTo (item) {
      return item.to ? item.to : null
    },

    itemHref (item) {
      return item.href ? item.href : null
    },

    logout () {
      this.$store.dispatch('signUserOut')
    }
  },

  created () {
    // console.log(' menu: ', this.menu)
  }
}

</script>

/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import HeroBar from '@/components/HeroBar'
import CardComponent from '@/components/CardComponent'
import ConfirmModalBox from '@/components/elements/modals/ConfirmDelete'
import SectionEmpty from '@/views/partials/SectionEmpty'

export default {
  components: {
    HeroBar,
    CardComponent,
    "app-modal-box": ConfirmModalBox,
    "app-section-empty": SectionEmpty
  },

  data () {
    return {
      isModalActive: false,
      trashObject: null,
      // Remove after implementing new Table component
      // Do we need?
      paginated: true,
      // Set perPage on backend
      perPage: 15,
      indefinteToast: null,
      // total: 0,
      // page: 1,
    }
  },

  computed: {
    ...mapGetters([
      'items',
      'error',
      'loading',
      'isLoggedUser',
      'paginate',
      'pagination',
      'ability'
    ]),

    /**
     * get name of trashed object - might be title or name
     */
    trashObjectName () {
      if (this.trashObject) {
        if (this.trashObject.hasOwnProperty('name')) {
          // console.log('name is returned')
          return this.trashObject.name
        }
        // console.log('title is returned')
        return this.trashObject.title
      }

      return null
    },

    /**
     * value deleted from backend is date or '-' or null
     * (TODO: change to be null!)
     */
    trashObjectDeleted () {
      if (this.trashObject) {
        // console.log('deleted: ', this.trashObject, this.trashObject.deleted)
        return this.trashObject.deleted
      }

      return null
    }
  },

  methods: {
    ...mapActions([
      'fetchItems',
      'fetchItem',
      'deleteItem',
      'saveItem',
    ]),

    /**
      * Handle page-change event
     */
    onPageChange(page) {
      this.page = page
      this.fetchPaginatedItems()
    },

    fetchPaginatedItems () {
      if(typeof this.page === 'undefined') {
        this.page = 1
      }
      this.fetchItems(this.endpoint + '?page=' + this.page)
        .then(response => {
          this.isLoading = false
          this.total = this.pagination.total
          // this.perPage = this.pagination.per_page
          // console.log(this.pagination.current_page, this.pagination.total, this.total)
        })
        .catch(error => {
          this.isLoading = false
          if (!null === this.error) {
            this.$toast.danger(' Error: ' + this.error)
          }
          console.log('ERROR fetchItems: ', error)
        })
    },

    isDeleted(deleted) {
      if (deleted === null) {
        // console.log('is null')
        return '-'
      }
      return deleted
    },

    deleteModal (trashObject) {
      // console.log('trashObject: ', trashObject)
      this.trashObject = trashObject
      this.isModalActive = true
    },

    confirmDelete (endpointSent) {
      // Reporting doesn't use softDeletes, so destroy the record
      this.isModalActive = false
      let index = this.items.indexOf(this.trashObject)
      // console.log('confirmed: ', this.trashObject , endpointSent)
      let action = 'delete'

      if(typeof(endpointSent) !== "undefined") {
        this.endpoint = endpointSent
      }

      let endpoint = this.endpoint + '/' + this.trashObject.slug

      // sometimes you get from backend deleted_at (rarely), sometimes deleted
      if((typeof this.trashObject.deleted_at !== 'undefined' && this.trashObject.deleted_at !== null) || this.trashObject.deleted !== null) {
        // console.log( ' DESTROY ')
        action = 'destroy'
        endpoint = this.endpoint + '/' + action + '/' + this.trashObject.slug
      }
      // console.log('indexof: ', this.trashObject.slug, index)
      let obj = {
        value: this.trashObject,
        endpoint: endpoint,
        index: index,
        action: action
      }
      console.log('action?', action, this.trashObject.deleted_at)
      // console.log('delete: ', obj)
      /*this.deleteItem(obj)
        .then(response => {
          this.$toast.success(this.$getTranslation('form.general.deleted'))
        })
        .catch(error => {
          console.log('error deleting record: ', error)
        })*/
    },

    cancel () {
      this.isModalActive = false
    },
  },

  mounted() {
    // console.log('ListTemplateMixin loaded')
  }
}

import { ToastProgrammatic as Toast } from 'buefy'

/**
 * crated global method $toast in main.js
 * @usage: this.$toast.success(message)
 *
 * another option is to include file on selected component
 * import successToast from '@/toast'
 * console.log(successToast.success('xxx'))
 */

/**
 * success message
 *
 * @param  {string} message
 *
 * @return {toast}
 */
function success (message) {
  // Toast.open(message)
  let obj = {
    message: message,
    type: 'is-success',
    // position: 'top',
  }
  Toast.open(obj)
}

/**
 * error message
 *
 * @param  {string} message
 *
 * @return {toast}
 */
function danger (message) {
  // Toast.open(message)
  let obj = {
    message: message,
    type: 'is-danger',
    // position: 'top',
  }
  Toast.open(obj)
}

/**
 * indefinite message, closed only on button click
 *
 * @param  {string} message
 *
 * @return {toast}
 */
function indefinite (message) {
  let obj = {
    indefinite: true,
    message: message,
    type: 'is-warning',
    position: top,
  }
  Toast.open(obj)
}

/**
 * clear indefinite message (NOT TESTED yet)
 */
function closeIndefinite () {
  if (this.indefinte) {
      this.indefinte.close();
      this.indefinte = null;
  }
}

export default {
  success,
  danger,
  indefinite,
  closeIndefinite
}

import { baseTitle } from '@/api-routes'
import i18n from '@/i18n'

// Use lazy loading
// add path as 'user/Index', 'Home', ...
function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}`)
}
export default [
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    meta: {
      title: route => { return baseTitle },
      routeName: i18n.t('general.views.home'),
      forVisitors: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('Home'),
    props: { activeTab: 0},
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('auth.signin.title')},
      routeName: i18n.t('auth.signin.title'),
      // the page can only be accessed if you are not logged in
      forVisitors: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: loadView('Home'),
    props: { activeTab: 1},
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('auth.signup.title') },
      routeName: i18n.t('auth.signup.title'),
      forVisitors: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: loadView('auth/ForgotPassword'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('auth.forgot.title')},
      routeName: '',
      forVisitors: true
    }
  },
  {
    path: '/reset-password/:token?',
    name: 'reset',
    component: loadView('auth/ResetPassword'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('auth.reset.title') },
      routeName: ''
    }
  },
  // Invitations
  {
    path: '/invitations',
    name: 'invitations',
    component: loadView('invitations/Index'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.invitation', 1) },
      routeName: i18n.tc('general.views.invitation', 1),
      forAuth: true
    }
  },
  {
    path: '/invitations/create',
    name: 'invitations.create',
    component: loadView('invitations/Form'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.invitation') },
      routeName: i18n.t('general.views.invitation'),
      forAuth: true
    }
  },
  // TODO - provide component and slug or sth like it
  {
    path: '/invitations/:slug/edit',
    name: 'invitations.edit',
    // component: loadView('invitations/Form'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.invitation') },
      routeName: i18n.t('general.views.invitation'),
      forAuth: true
    }
  },
  // Accept / reject invitation
  {
    path: '/invitations/view/:token/:media?',
    name: 'invitations-view',
    component: loadView('invitations/AcceptRejectForm'),
    props: true,
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.invitation') },
      routeName: i18n.t('general.views.invitation'),
      type: 'invitation',
      forAuth: false
    }
  },

  // Messaging
  {
    path: '/messages',
    name: 'messaging',
    component: loadView('messaging/Index'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.messaging') },
      routeName: i18n.t('general.views.messaging'),
      forAuth: true
    }
  },

  {
    name: 'messaging.create',
    path: '/messages/create',
    component: loadView('messaging/Form'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.messaging') },
      routeName: i18n.t('general.views.messaging'),
      forAuth: true
    }
  },

  {
    name: 'messaging.view',
    path: '/messages/:slug/view',
    component: loadView('messaging/Details'),
    props: true,
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.messaging') },
      routeName: i18n.t('general.views.messaging'),
      forAuth: true
    }
  },


  // Admin routes - only for admin
  {
    name: 'activities',
    path: '/admin/activities',
    component: loadView('activities/Index'),
    meta: {
      // return custom title based on route, store or anything
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.logActivities') },
      routeName: i18n.t('general.views.logActivities'),
      forAuth: true,
      resource: 'admin'
    }
  },
  /* Links forAuth */
  // Logged in user
  {
    name: 'dashboard',
    path: '/dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.dashboard') },
      routeName: i18n.t('general.views.dashboard'),
      forAuth: true
    }
  },

  // Users
  {
    name: 'users',
    path: '/users',
    component: () => import('../views/user/Index'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.user', 1, true) },
      routeName: i18n.tc('general.views.user', 1, true),
      forAuth: true,
      resource: 'user'
    }
  },
  {
    name: 'participant',
    path: '/participants',
    component: () => import('../views/user/Participants'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.participant', 1, true) },
      routeName: i18n.tc('general.views.participant', 1, true),
      forAuth: true,
      resource: 'user'
    }
  },
  {
    name: 'collaborator',
    path: '/collaborators',
    component: () => import('../views/user/Index'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.collaborator', 1, true) },
      routeName: i18n.tc('general.views.collaborator', 1, true),
      forAuth: true,
      resource: 'user',
      action: 'list'
    }
  },
  {
    name: 'users.create',
    path: '/users/create',
    component: () => import('../views/user/Form'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.user', 0) + ' | ' + i18n.t('form.general.create')},
      routeName: i18n.t('form.general.create') + ' ' +  i18n.tc('general.views.user', 0),
      forAuth: true,
      resource: 'user',
      action: 'create'
    }
  },
  {
    name: 'users.edit',
    path: '/users/:slug/edit',
    component: () => import('../views/user/Form'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.user', 0) + ' | ' + i18n.t('form.general.edit')},
      routeName: i18n.t('form.general.edit') + ' ' + i18n.tc('general.views.user', 0),
      forAuth: true,
      type: 'users',
      // Edit should work foreach user
      // resource: 'user',
      // action: 'update'
    }
  },
  // Surveys
  {
    name: 'surveys',
    path: '/surveys',
    component: () => import('../views/survey/Index'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.survey', 1, true) },
      routeName: i18n.tc('general.views.survey', 1, true),
      forAuth: true,
      resource: 'survinst',
      action: 'list'
    }
  },
  {
    name: 'surveys.detail',
    // TODO check - one of data has survey-key null
    path: '/surveys/:slug?',
    component: loadView('survey/Detail'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.survey', 0) + ' | ' +  i18n.t('form.general.details')},
      routeName: i18n.tc('general.views.survey', 0) + ' ' +  i18n.t('form.general.details'),
      forAuth: true,
      resource: 'survinst',
      action: 'read'
    }
  },
  {
    path: '/profile/:slug?',
    name: 'profile',
    component: () => import('../views/user/Profile'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.profile')},
      routeName: i18n.t('general.views.profile'),
      forAuth: true,
      props: true
    }
  },

  // management: roles, permissions
  {
    path: '/roles',
    name: 'roles',
    component: loadView('management/Roles'),
    meta: {
      type: 'roles',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.role',1)},
      routeName: i18n.tc('general.views.role', 1),
      forAuth: true,
      resource: 'admin'
    }
  },
  {
    name: 'roles.create',
    path: '/roles/create',
    component: loadView('management/Form'),
    meta: {
      type: 'roles',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.role', 0) + ' | ' + i18n.t('form.general.create') },
      routeName: i18n.t('form.general.create') + ' ' + i18n.tc('general.views.role', 0),
      forAuth: true,
      resource: 'admin'
    }
  },
  {
    name: 'roles.edit',
    path: '/roles/:slug/edit',
    component: loadView('management/Form'),
    props: true,
    meta: {
      type: 'roles',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.role', 0) + ' | ' + i18n.t('form.general.create') },
      routeName: i18n.t('form.general.edit') + ' ' + i18n.tc('general.views.role', 0),
      forAuth: true,
      resource: 'admin'
    }
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: loadView('management/Permissions'),
    meta: {
      type: 'permissions',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.permission', 1)},
      routeName: i18n.tc('general.views.permission', 1),
      forAuth: true,
      resource: 'admin'
    }
  },
  {
    name: 'permissions.create',
    path: '/permissions/create',
    component: loadView('management/Form'),
    props: true,
    meta: {
      type: 'permissions',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.permission', 0) + ' | ' + i18n.t('form.general.create') },
      routeName: i18n.t('form.general.create') + ' ' + i18n.tc('general.views.permission', 0),
      forAuth: true,
      resource: 'admin'
    }
  },
  {
    name: 'permissions.edit',
    path: '/permissions/:slug/edit',
    component: loadView('management/Form'),
    props: true,
    meta: {
      type: 'permissions',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.permission', 0) + ' | ' + i18n.t('form.general.edit') },
      routeName: i18n.t('form.general.edit') + ' ' + i18n.tc('general.views.permission', 0),
      forAuth: true,
      resource: 'admin'
    }
  },
  // Measurement Scales
  {
    path: '/measurement',
    name: 'measurement',
    component: loadView('measurementscales/Index'),
    meta: {
      type: 'measurement',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.measurement', 1)},
      routeName: i18n.tc('general.views.measurement', 1),
      forAuth: true,
      resource: 'scale'
    }
  },
  {
    name: 'measurement.detail',
    path: '/measurement/:scaleId',
    component: loadView('measurementscales/Detail'),
    props: true,
    meta: {
      type: 'measurement',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.measurement', 0) + ' | ' + i18n.t('form.general.details') },
      routeName: i18n.t('form.general.create') + ' ' + i18n.tc('general.views.measurement', 0),
      forAuth: true,
      resource: 'scale'
    }
  },
  {
    name: 'measurement.create',
    path: '/measurement/create',
    component: loadView('measurementscales/Form'),
    props: true,
    meta: {
      type: 'measurement',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.measurement', 0) + ' | ' + i18n.t('form.general.create') },
      routeName: i18n.t('form.general.create') + ' ' + i18n.tc('general.views.measurement', 0),
      forAuth: true,
      resource: 'scale',
      action: 'create'
    }
  },
  {
    name: 'measurement.edit',
    path: '/measurement/:slug/edit',
    component: loadView('measurementscales/Form'),
    props: true,
    meta: {
      type: 'measurement',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.measurement', 0) + ' | ' + i18n.t('form.general.edit') },
      routeName: i18n.t('form.general.edit') + ' ' + i18n.tc('general.views.measurement', 0),
      forAuth: true,
      resource: 'scale',
      action: 'update'
    }
  },
  // Response Scales
  {
    path: '/response',
    name: 'response',
    component: loadView('responsescales/Index'),
    meta: {
      type: 'response',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.response', 1)},
      routeName: i18n.tc('general.views.response', 1),
      forAuth: true,
      resource: 'scale'
    }
  },
  {
    name: 'response.create',
    path: '/response/create',
    component: loadView('responsescales/Form'),
    props: true,
    meta: {
      type: 'response',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.response', 0) + ' | ' + i18n.t('form.general.create') },
      routeName: i18n.t('form.general.create') + ' ' + i18n.tc('general.views.response', 0),
      forAuth: true,
      resource: 'scale',
      action: 'create'
    }
  },
  {
    name: 'response.edit',
    path: '/response/:slug/edit',
    component: loadView('responsescales/Form'),
    props: true,
    meta: {
      type: 'response',
      title: route => { return baseTitle + ' | ' + i18n.tc('general.views.response', 0) + ' | ' + i18n.t('form.general.edit') },
      routeName: i18n.t('form.general.edit') + ' ' + i18n.tc('general.views.response', 0),
      forAuth: true,
      resource: 'scale',
      action: 'update'
    }
  },
  // REPORTING
  {
    path: '/reporting',
    name: 'reporting',
    component: loadView('reporting/Index'),
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.reporting')},
      routeName: i18n.t('general.views.reporting'),
      forAuth: true,
      resource: 'report'
    }
  },
  {
    name: 'reporting.create',
    path: '/reporting/create',
    component: loadView('reporting/Form'),
    props: true,
    meta: {
      type: 'reporting',
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.report') + ' | '  + i18n.t('form.general.create')},
      routeName: i18n.t('form.general.create') + ' ' + i18n.t('general.views.report'),
      forAuth: true,
      resource: 'report',
      action: 'create'
    }
  },
  {
    name: 'reporting.edit',
    path: '/reporting/:slug/edit',
    component: loadView('reporting/Form'),
    props: true,
    meta: {
      type: 'reporting',
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.report') + ' | ' + i18n.t('form.general.edit')},
      routeName: i18n.t('form.general.edit') + ' ' + i18n.t('general.views.report'),
      forAuth: true,
      resource: 'report',
      action: 'update'
    }
  },
  {
    name: 'reporting.view',
    path: '/reporting/:slug/view',
    component: loadView('reporting/PdfList'),
    props: true,
    meta: {
      type: 'reporting',
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.report') + ' | ' + i18n.t('form.general.edit')},
      routeName: i18n.t('form.general.edit') + ' ' + i18n.t('general.views.report'),
      forAuth: true,
      resource: 'report',
      action: 'update'
    }
  },
  {
    path: '/reporting/details/:slug/:color',
    name: 'reporting.details',
    component: loadView('reporting/Report'),
    props: true,
    meta: {
      type: 'reportings',
      title: route => { return baseTitle + ' | ' +  i18n.t('general.views.report')},
      routeName: i18n.t('general.views.report'),
      forAuth: true,
      resource: 'report'
    }
  },
  {
    path: '/reporting/details/:slug/:color/:division/:type',
    name: 'reporting.details.children',
    component: loadView('reporting/Report'),
    props: true,
    meta: {
      type: 'reportings',
      title: route => { return baseTitle + ' | ' +  i18n.t('general.views.report')},
      routeName: i18n.t('general.views.report'),
      forAuth: true,
      resource: 'report'
    }
  },
  // Related to generate report
  {
    path: '/reporting/pdfList/:slug/:color',
    name: 'reporting.pdf-list',
    component: loadView('reporting/PdfList'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' |  ' +  i18n.t('general.views.report') +' | PDF ' + i18n.t('form.general.list') },
      routeName: i18n.t('general.views.report') +' PDF ' + i18n.t('form.general.list'),
      forAuth: true,
      resource: 'report'
    }
  },
  {
    path: '/reporting/pdfExport/:slug/:level/:type/:color',
    name: 'reporting.pdf-export',
    component: loadView('reporting/PdfExport'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' |  ' +  i18n.t('general.views.report') +' | PDF ' + i18n.t('form.general.export') },
      routeName: i18n.t('general.views.report') +' PDF ' + i18n.t('form.general.export'),
      forAuth: true,
      resource: 'report'
    }
  },
  {
    path: '/reporting/pdfDownload/:slug/:color',
    name: 'reporting.pdf-download',
    component: loadView('reporting/PdfExport'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' |  ' +  i18n.t('general.views.report') +' | PDF ' + i18n.t('form.general.download') },
      routeName: i18n.t('general.views.report') +' PDF ' + i18n.t('form.general.export'),
      forAuth: true,
      resource: 'report'
    }
  },
  {
    path: '/reporting/pdfPreview/:slug/:level/:type/:color',
    name: 'reporting.pdf-preview',
    component: loadView('reporting/PdfPreview'),
    props: true,
    meta: {
      title: route => { return baseTitle + ' | ' + i18n.t('general.views.report') + ' | PDF ' + i18n.t('form.general.preview') },
      routeName: i18n.t('general.views.report') +' PDF ' + i18n.t('form.general.preview'),
      forAuth: true,
      resource: 'report'
    }
  },
]

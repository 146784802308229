// for shared getters, mutations, actions ...
import {
  SET_LOADING
} from '../../mutation-types'

const state = {
  loading: false
}

const mutations = {
  [SET_LOADING] (state, payload) {
    state.loading = payload
  }
}

const getters = {
  loading: state => state.loading
}

const actions = {
  // setLoading: T/F
  setLoading ({ commit }, payload) {
    commit(SET_LOADING, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

// TODO: remove - NOT in use
import axios from 'axios'

import {
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR
} from '../../mutation-types'

import {
} from '../../../api-routes'

/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  activities: null,
  activity: null,
}

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  setActivities (state, payload) {
    state.activities = payload
  },
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  activities: state => state.activities,
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  /**
   * fetch all activities
   */
  fetchLogActivities ({ commit, getters }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    axios
      .get(payload)
      .then(response => {
        commit(SET_LOADING, false)
        let activities = response.data.data
        // console.log('activities: ', activities);
        if (
          activities === undefined ||
          activities.length === 0
        ) {
          commit(SET_ERROR, 'activities not found')
        } else {
          commit('setActivities', activities)
        }
      })
      .catch(error => {
        console.log('STORE error fetching activities: ', error)
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data.errors.error);
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

// can work with json or js files
import messages from './translations'
import store from '@/store'

// https://itnext.io/setting-language-with-i18n-by-os-language-in-vue-router-vuex-e42c9318c9ec
Vue.use(VueI18n)

/*const dateTimeFormats = {
  'en': {
    short: new Date().toISOString().slice(0, 10),
    long: ''
  },
  'sl': {
    short: new Date().toISOString().slice(0, 10),
    long: ''
  }
}
*/

/**
 * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} an overall amount of available choices
 * @returns a final choice index to select plural word by
**/
VueI18n.prototype.getChoiceIndex = function (choice, choicesLength) {
  // console.log(' locale -get choice? ', this.locale)
  // this === VueI18n instance, so the locale property also exists here
  if (this.locale !== 'ru') {
    // proceed to the default implementation
  }

  if (choice === 0) {
    return 0
  }

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) {
    return 1
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2
  }

  return (choicesLength < 4) ? 2 : 3
}

export default new VueI18n({
  // en
  locale: 'en',
  fallbackLocale: 'de',
  messages,
  // dateTimeFormats
  silentFallbackWarn: true
})

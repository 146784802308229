import general from './json/en/general.json'
import form from './json/en/form.json'
import auth from './json/en/auth.json'
import profile from './json/en/profile.json'
import surveys from './json/en/surveys.json'
import invitation from './json/en/invitation.json'
import messaging from './json/en/messaging.json'
import menu from './json/en/menu.json'

export default {
  general,
  form,
  auth,
  profile,
  surveys,
  invitation,
  messaging,
  menu
}
import axios from 'axios'
import router from '@/router'
import i18n from '@/i18n'

import {
  CLEAR_ERROR,
  REMOVE_AUTH_USER,
  SET_AUTH_USER,
  SET_ERROR,
  SET_LOADING,
  SET_REGISTERED_USER,
  SET_USER,
  SET_USER_PERMISSIONS,
  SET_USER_ROLES,
  SET_ABILITY_RULES
} from '@/store/mutation-types'

import {
  ApiForgotPassword,
  ApiLogin,
  ApiLogout,
  ApiRegister,
  ApiResetPassword,
  ApiUsers
} from '@/api-routes'
import { AbilityBuilder } from '@casl/ability'
import defineAbilitiesFor from '@/abilityFunc.js'

/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  authUser: null,
  // only in router.js
  isLoggedIn: !!localStorage.getItem('token'),
  userRoles: null,
  userPermissions: null,
  ability: null,
  // object with properties used in localStorage
  userSessionObj: {
    userId: null,
    userProperName: null,
    slug: null,
    email: null,
    expiration: null,
    token: null,
    roles: null,
  },
  // Mapped session array
  userSessionArr: [
    'userId',
    'userProperName',
    'slug',
    'email',
    'expiration',
    'token',
    'permissions',
    'roles'
  ],
  // user we get from backend, first value is mapped name of localStorage name,
  // second value is actual value, set to be empty
  userObj: {
    id: ['userId', ''],
    name: ['userProperName', ''],
    slug: ['slug', ''],
    email: ['email', ''],
    expires_in: ['expiration', ''],
    token: ['token', localStorage.getItem('token')],
    roles: ['roles', ''],
    permissions: ['permissions', ''],
  },
  // fetching user
  user: null,
}

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  [SET_AUTH_USER] (state, payload) {
    // if localStorage item undefined, returns null
    // let map = {}
    let roles = [];
    let permissions = [];
    Object.keys(state.userObj).forEach(key => {
      let mapKey = state.userObj[key][0]
      // console.log('mapKey', mapKey)
      if (state.userSessionArr.includes(mapKey)) {
        if (payload[key] === undefined) {
          // This block rehydrates most user state fields (except expiration and token) on refresh
          let localValue = localStorage.getItem(mapKey)
          state.userSessionObj[mapKey] = localValue
          if (key === 'permissions') {
            permissions = localValue.split(',')
          } else if (key === 'roles') {
            roles = localValue.split(',')
          }
        } else {
          // This block inits all user state fields on login
          // and rehydrates expiration and token on refresh
          if(key === 'roles') {
            payload[key].forEach(item => { roles.push(item.slug) })
          } else if(key === 'permissions') {
            payload[key].forEach(item => { permissions.push(item.slug) })
          } else {
            state.userSessionObj[mapKey] = payload[key]
          }
        }
        // map[mapKey] = payload[key];
      }
    })

    // Set roles only on login, otherwise read from localStorage
    if (Array.isArray(roles) && roles.length)  {
      localStorage.setItem('roles', roles.join())
      state.userSessionObj['roles'] = roles.join()
    }

    if (Array.isArray(permissions) && permissions.length)  {
      localStorage.setItem('permissions', permissions.join())
      state.userSessionObj['permissions'] = permissions.join()
    }
    // console.log(ability)
     state.ability = defineAbilitiesFor(permissions)
    // console.log(ability.rules, this.$ability)
    // console.log('ability: ', ability.rules, ability1.rules)
    // ability.update(rules)

    // console.log('end authUser: ', state.userSessionObj, map);
    // Set up local storage for all keys
    Object.keys(state.userSessionObj).forEach(key => {
      // console.log('new obj key: ', key, ' val: ', state.userSessionObj[key]);
      localStorage.setItem(key, state.userSessionObj[key])
      // console.log('get session: ', localStorage.getItem(key));
    })
    // Setup last params for getters: cleaned user and login as successful
    state.isLoggedIn = true
    state.authUser = state.userSessionObj
    state.userPermissions = permissions
    state.userRoles = roles
    // console.log('session: ', state.userSessionObj, state.authUser)
    // console.log('state user: ', state.authUser, state.authUser.userId, localStorage.getItem('userId'), state.userSessionObj);
  },

  // Registration treated differently ??
  [SET_REGISTERED_USER] (state, user) {
    state.authUser = user
  },

  [SET_USER] (state, payload) {
    state.user = payload
  },

  /*[SET_USER_ROLES] (state, payload) {
    state.userRoles = payload
  },

  [SET_USER_PERMISSIONS] (state, payload) {
    state.userPermissions = payload
    // console.log('mutations: ', payload)
  },*/

  [SET_ABILITY_RULES] (state, payload) {
    state.ability = payload
  },

  [REMOVE_AUTH_USER] (state) {
    localStorage.clear()
    state.isLoggedIn = false
    location.reload()
  }
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  //  isLoggedIn: state => {
  //      return state.isLoggedIn
  // },
  isAdmin: state => {
    if (null!= state.userSessionObj.roles) {
      return state.userSessionObj.roles.includes("admin")
    } else if (localStorage.getItem('roles')) {
      return localStorage.getItem('roles').includes("admin")
    }
    return false
  },
  // if user is logged in or not (boolean)
  isLoggedIn: state => state.isLoggedIn,
  // in user state are saved all user data with permissions, roles
  isLoggedUser: state => state.authUser,
  user: state => state.user,
  userPermissions: state => state.userPermissions,
  userRoles: state => state.userRoles,
  ability: state => state.ability,
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  forgotPassword ({ commit }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    axios
      .post(ApiForgotPassword, payload)
      .then(response => {
        commit(SET_LOADING, false)
        commit(SET_ERROR, null)
        if (!response.data.success ) {
          commit(SET_ERROR, response.data.message)
        } else {
          commit(SET_ERROR, null)
          commit(SET_LOADING, true)
        }
      })
      .catch(error => {
        commit(SET_LOADING, false)
        // console.log('ERROR forgot pass: ', error.response.status)
        commit(SET_ERROR, error.response.data.message)
      })
  },

  /**
   * setPasswords method for resetPassword, acceptInvitation, rejectInvitation
   */
  setPasswords ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(SET_LOADING, true)
      commit(CLEAR_ERROR)

      axios
        .post(payload.endpoint, payload.data)
        .then(response => {
          commit(SET_LOADING, false)
          resolve(response)
        })
        .catch(error => {
          console.log('ERROR?? ', error)
          if (error.response.status === 422) {

            if (typeof error.response.data.errors !== 'undefined') {
              // let err = error.response.data.errors
              commit(SET_ERROR, error.response.data.errors)
              // console.log(' not undefined (array): ', ' errors:', error.response.data.errors, ' message: ', error.response.data.message+ Object.keys(error.response.data.errors)[0], ' ERR: ', err)
              reject(error.response.data)
            } else {
              commit(SET_ERROR, error.response.data.error)
              reject(error.response.data.error)
            }

          } else {
            // console.log('error:', error.response.data.errors)
            commit(SET_ERROR, error)
            reject(error)
          }
          // commit(SET_LOADING, true)
          // commit(SET_ERROR, error.response.data);
          // console.log('ERROR reset pass: ', error.response, error.response.data.errors, Object.keys(error.response.data.errors)[0], error.response.status)
        })
    });
  },

  /**
   * create user
   * user can be created also by super-user(admin, customer),
   * SET_REGISTERED_USER in this case should not be set
   */
  signUserUp ({ commit }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    let endpoint = ApiRegister
    if(this.state.userStore.authUser !== null) {
      endpoint = ApiUsers
    }
    axios
      .post(endpoint, payload)
      .then(response => {
        commit(SET_LOADING, false)
        if(this.state.userStore.authUser !== null) {
          router.push('/users')
        }
      })
      .catch(error => {
        let err = ''

        for (const [key, value] of Object.entries(error.response.data.errors)) {
          err += value[0] + "<br>"
        }

        commit(SET_LOADING, false)

        commit(SET_ERROR, err)
        // console.log('ERROR register: ', error.response.data.errors)
      })
    // console.log('register payload store: ', payload);
  },

  signUserOut ({ commit }) {
    commit(SET_LOADING, true)
    // console.log('LOGOUT');
    axios
      .post(ApiLogout)
      .then(response => {
        commit(SET_LOADING, false)
        // console.log('logout res: ', response)
        let payload = {
          user: null,
          isLoggedIn: false
        }

        commit(REMOVE_AUTH_USER, payload)
      })
      .catch(error => {
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data)
        console.log('ERROR logout: ', error)
      })
  },

  signUserIn ({ commit }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    payload.email = payload.username

    return axios
      .post(ApiLogin, payload)
      .then(response => {
        commit(SET_LOADING, false)
        // console.log('user logged in? ', response)
        let data = response.data.success
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
        data.expires_in = data.expires_in + Date.now()

        // console.log('sign in: ', data.roles)
        commit(SET_AUTH_USER, data)
        // commit(SET_USER_ROLES, data.roles)
        // commit(SET_USER_PERMISSIONS, data.permissions)
        // console.log('login data: ', data, this.state.userStore.userPermissions)
        return data;
      })
      // Error already caught with interceptors
      .catch(error => {
        commit(SET_LOADING, false)
        // console.log('error?', error.response)
        if (error.response.status === 401) {
          commit(SET_ERROR, 'You are not authorized')
        } else {
          console.log('error:', error)
          commit(SET_ERROR, 'Undefined error')
        }
      })
  },

  // get logged in user details - on dashboard
  // on each reload
  getUser: ({ commit }) => {
    let token = localStorage.getItem('token')
    let expiration = localStorage.getItem('expiration')

    // console.log('GET USER ACTION ', expiration);
    let payload = {
      user: null,
      isLoggedIn: false
    }
    // if (token || expiration) {
    // if (!token || expiration === 'undefined') {
    if (!token || !expiration) {
      // console.log('GET USER ACTION - remove');
      commit(REMOVE_AUTH_USER, payload)
      return null
    }

    if (Date.now() > parseInt(expiration)) {
      // console.log('GET USER ACTION - date - remove');
      commit(REMOVE_AUTH_USER, payload)
      return null
    }

    let user = {}
    let userProperName = localStorage.getItem('userProperName')

    // Should never happen
    if (typeof userProperName === 'undefined') {
      axios
        .get(ApiUsers + '/' + localStorage.getItem('slug'))
        .then(response => {
          let data = response.data
          user.slug = data.slug
          user.name = data.name

          console.log('STORE-GET USER - IF userProperName UNDEFINED',
            response.data)
          commit(SET_AUTH_USER, user)
        })
        .catch(error => {
          console.log('get user error !!!', error)
        })
    }
    // console.log('authUser: ', state.authUser)
    user = {
      token: token,
      expires_in: localStorage.getItem('expiration')
    }

    commit(SET_AUTH_USER, user)
    // console.log('authUser: ', state.authUser,state.ability)
    // commit(SET_USER_ROLES, state.authUser.roles)
    // commit(SET_USER_PERMISSIONS, state.authUser.permissions)
    // console.log('get user: ', state.authUser.permissions, state.userPermissions, state.ability);
  },

  // TODO: remove, before chaged to fetchItems - check registration
  fetchUser ({ commit }, slug) {
    axios
      .get(ApiUsers + '/' + slug)
      .then(response => {
        let data = response.data.data
        // console.log('data: ', response, response.status, response.data, data)
        commit(SET_USER, data)
        // commit(SET_USER_ROLES, data.roles)
        // commit(SET_USER_PERMISSIONS, data.permissions)
      })
      .catch(error => {
        if(error.response.status === 401) {
          commit(SET_ERROR, i18n.t('auth.not-authorized'))
          // console.log('title: ', i18n.t('auth.signin.title'))
        } else {
          console.log('get user error !!!', error.response.status, error.response.data, error.response.data.errors.error, error.response.data.message)
        }
      })
    // }
  },

  resetUser({ commit }) {
    commit(SET_USER, null)
    commit(SET_USER_ROLES, null)
    commit(SET_USER_PERMISSIONS, null)
  },

  updateUser ({ commit }, user) {
    commit(SET_AUTH_USER, user)
  },


  createUser ({ commit }, user) {
    // commit(SET_AUTH_USER, user)
  },

  fetchRolePermissions ({ commit }, role) {
    return new Promise((resolve, reject) => {
      axios
        .get(ApiUsers + '/role/getRolePermissions/' + role)
        .then(response => {
          let data = response.data.role

          resolve(data)
        })
        .catch(error => {
          // console.log('fetch user error !!!', error.response.status)
          // error.reponse props:  data, status, status text
          // (from Laravel: Unprocessable Entity)
          // other: headers, config, request..
          // console.log('ERR status: ', error.response)
          let errorResponse = '';
          if(error.response && error.response.status === 422) {
            errorResponse = error.response.data.errors.value[0];
            // console.log('status error 422, on empty error: ', error.response.data.errors.value[0] )
          } else {
            // console.log('not 422 error: ', error)
            errorResponse = error.response;
          }
          commit(SET_ERROR, error);
          reject(error)
        })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <b-dropdown
    position="is-top-right"
    append-to-body
    aria-role="menu">
    <a
      class="navbar-item"
      slot="trigger"
      role="button"
      slot-scope="{ active }">
      <span>{{ languages[$i18n.locale].title }}</span>
      <b-icon :icon="active ? 'menu-down' : 'menu-up'"/>
    </a>
    <b-dropdown-item has-link aria-role="menuitem"
      v-for="lang in Object.keys(languages).sort()" :key="lang">
      <a @click="changeLanguage(lang)">
        {{ languages[lang]['title'] }}
      </a>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapActions } from "vuex"
import Languages from "@/translations"
import NavBarMenu from '@/components/layout/NavBarMenu'

export default {
  name: "LanguagePicker",
  components: { NavBarMenu },
  data() {
    return {
      languages: {
        en: {
          title: this.$getTranslation("general.languages.english"),
          value: "en",
          flag: "gb"
        },
        de: {
          title: this.$getTranslation("general.languages.german"),
          value: "de",
          flag: "de"
        }
      }
    };
  },
  computed: {
    currentLocaleFlag() {
      return this.languages[this.$i18n.locale]["flag"]
    }
  },
  methods: {
    ...mapActions(['setLanguage']),

    changeLanguage(lang) {
      this.$i18n.locale = lang
      // not doing anything with the promise yet, so yeah …
      this.setLanguage(lang)
        .then(response => {
          //
        })
        .catch(error => {
          console.log('errors? ', error)
        })
    },
  },
  created () {
    //
  }
};
</script>

/* Styles */
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import axios from 'axios'
import Echo from "laravel-echo"
/* Router & Store */
import store from './store'
import router from './router'
import filters from "./filters"

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'

/* Vue. Component in recursion */
import AsideMenu from '@/components/layout/AsideMenu'

// moved to beforeEach
/* CASL Plugin for permissions application */

/* import { abilitiesPlugin } from '@casl/vue'
import ability from '@/ability'
import defineAbilitiesFor from '@/abilityFunc'
const ability = defineAbilitiesFor(localStorage.getItem("permissions"))
Vue.use(abilitiesPlugin, store.getters.ability)
Vue.use(abilitiesPlugin, ability); */

/* Social sharing */
// https://www.npmjs.com/package/vue-social-sharing
import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);


/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false)
})

Vue.config.productionTip = false

// Enable devtools
Vue.config.devtools = true
Vue.config.performance = true

/* These components are used in recursion algorithm */
Vue.component('AsideMenu', AsideMenu)

Vue.use(Buefy)

window.Pusher = require("pusher-js");
import { pusherConfig } from '@/api-routes.js'

/**
 * Authorization token
 */
// console.log('axios headers: ', axios.defaults)

let token = null

// console.log(pusherConfig)
window.Echo = null;

if (localStorage.getItem("token")) {
  token = "Bearer " + localStorage.getItem("token");
  axios.defaults.headers.common.Authorization = token;
  let echo = {
    broadcaster: "pusher",
    key: pusherConfig.pusher_key,
    appId: pusherConfig.pusher_id,
    secret: pusherConfig.pusher_secret,
    cluster: pusherConfig.pusher_cluster,
    // client: client,
    encrypted: true,
    authEndpoint: pusherConfig.echoAuthEndpoint,
    namespace: "",
    forceTLS: true,
    auth: {
      headers: {
        //Accept:'application/json',
        Authorization: token
      }
    }
  };
  window.Echo = new Echo(echo);
}
// console.log('permissions on main: ', permissions)
// console.log(window.Echo)
// Localization
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import i18n from "./i18n";

// Add helper method - needed for vuetify
// Global | moving to mixin | using utils functions
// all replaced in auth - is an alias to this.$t / $t
// param is an object { msg: 'hello' }
Vue.prototype.$getTranslation = function(value, param, pluralization) {
  if (typeof pluralization !== "undefined" && pluralization === true) {
    // console.log('plural: ', param)
    return this.$tc(value, param)
  }
  if (typeof param !== "undefined" && param >= 0) {
    return this.$tc(value, 0)
  }

  return this.$t(value)
}

// Create global messages for Toast
// usage: console.log(this.$Toast.success('woohoo'))
import successToast from '@/toast'
Vue.prototype.$toast = successToast

// Filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

// Shared
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'

// Auth
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const SET_REGISTERED_USER = 'SET_REGISTERED_USER'
export const REMOVE_AUTH_USER = 'REMOVE_AUTH_USER'

// User
export const SET_USER = 'SET_USER'
export const SET_USER_ROLES = 'SET_USER_ROLES'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
// User-abilities depending on permissions
export const SET_ABILITY_RULES = 'SET_ABILITY_RULES'


// Organizations
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_ORGANIZATION_DIVISIONS = 'SET_ORGANIZATION_DIVISIONS'
export const SET_ORGANIZATION_USERS = 'SET_ORGANIZATION_USERS'

// Item
export const SET_ITEM = 'SET_ITEM'
export const SET_ITEMS = 'SET_ITEMS'

// Language
export const SET_LANGUAGE = 'SET_LANGUAGE'

// Messages
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_MESSAGES = 'SET_MESSAGES'
export const SET_MESSAGES_COUNT = 'SET_MESSAGES_COUNT'
<template>
  <div id="app" :class="loggedInWatcher">
    <div v-if="isLoggedIn && isLoggedUser !== null">
      <!-- <nav-bar/> -->
      <aside-menu/>
      <!-- <footer-bar/> -->
    </div>
    <app-content></app-content>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import AsideMenu from '@/components/layout/AsideMenu'
import Content from '@/components/layout/Content'
// import NavBar from '@/components/layout/NavBar'
// import FooterBar from '@/components/layout/FooterBar'

export default {
  name: 'home',
  components: {
    AsideMenu,
    // FooterBar,
    // NavBar,
    'app-content': Content,
  },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isLoggedUser'
    ]),
    loggedInWatcher () {
      const html = document.getElementsByTagName('html')[0]
      html.classList.remove('not-logged-in')
      html.classList.remove('logged-in')
      html.classList.remove('has-navbar-fixed-top')
      if (this.isLoggedIn && this.isLoggedUser !== null) {
        // console.log('user is authenticated: ', this.isLoggedUser, this.isLoggedIn)
        html.classList.add('logged-in')
        html.classList.add('has-navbar-fixed-top')
        return 'loggedIn'
      }
      html.classList.add('not-logged-in')
      html.classList.remove('has-navbar-fixed-top')
      // console.log(' user is not authenticated ', this.isLoggedUser, this.isLoggedIn)
      return 'loggedOut'
    }
  },
  methods: {
    ...mapActions([
      'getUser',
      'signUserOut'
    ])
  },
  created () {
    if (this.isLoggedIn) {
      this.getUser()
    }
    // Use interceptors for cases when the token is expired,
    // the user is unauthorized
    axios.interceptors.response.use(undefined, function (err) {
      // console.log('INTERCEPTORS??');
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // if you ever get an unauthorized, logout the user
          this.signUserOut()
          // console.log('INTERCEPTORS, logout')
          // you can also redirect to /login if needed !
        }
        throw err
      })
    })

    // Catch responses with axios interceptors
    // Not in use, we use vuex only at the moment
    /*axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      console.log('interceptors response: ', response, response.status, response.data)
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      console.log('interceptors error: ', error.response.status, error.response.data, error.response.data.errors.error, error.response.data.message)
      return Promise.reject(error);
    });*/
  }
}
</script>

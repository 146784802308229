import Vue from 'vue'
import Vuex from 'vuex'

import errors from './modules/shared/errors'
import loading from './modules/shared/loading'
import commonStore from './modules/common'
import userStore from './modules/user'
import organizationStore from './modules/organization'
import surveyStore from './modules/surveys'
import logActivitiesStore from './modules/logActivities'
import uiStore from './modules/ui'
import language from './modules/language'
import pagination from './modules/common/pagination'
import messaging from './modules/messaging'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    errors,
    loading,
    commonStore,
    userStore,
    organizationStore,
    surveyStore,
    logActivitiesStore,
    uiStore,
    language,
    pagination,
    messaging
  },
  state: {
    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    }
  },
  actions: {

  }
})

<template> <!-- new notification {{ id }} --> </template>

<script>
import Echo from "laravel-echo"
import { mapGetters, mapActions } from 'vuex'
import { ApiMessaging } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
// import { ADD_USER_NOTIFICATION } from '../../store/mutation-types';

export default {
  props: ['userId'],
  mixins: [ListTemplateMixin],

  data() {
    return {
      endpoint: ApiMessaging + '/unread'
    }
  },

  computed: {
    ...mapGetters([
      'messages',
      // count is calculated in UnreadNotifications
      'allMessagesCount'
    ]),
  },

  mounted() {
    // console.log('mounted notification');
    this.listen();
  },

  methods: {
    ...mapActions([
      'fetchMessages',
      'setItems'
    ]),
    listen() {
      // console.log( 'ID: ', this.userId);
      // this.clearAlert();
      if(window.Echo !== null) {
        // console.log('ECHO NOT NULL')
        window.Echo.private('mvp-channel.' + this.userId)
          // using events
           .listen(".App\\Events\\MessageSent", (event) => {
            // alert('listening...')
            // console.log('Private MessageSent: ' + event.body, event, this.userId)
              let message = 'Message Sent From: ' + event.sender + event.body
              this.$toast.success(message);
              // Read unread messages - counter
              this.fetchMessages('unread')
              // Refresh unread messages
              this.fetchPaginatedItems()
            })

           .listen(".App\\Events\\MessageDelivered", (event) => {
              // console.log('Private MessageDelivered: ' + event.body, event, this.userId)
              let message = 'Message Delivered To: ' + event.receiver + event.body
              this.$toast.success(message);
              // Read unread messages - counter
              this.fetchMessages('unread')
              // Refresh unread messages
              this.fetchPaginatedItems()
            })
           .listen(".App\\Notifications\\MailArrived", (event) => {
              // console.log('Private MessageDelivered: ' + event.body, event, this.userId)
              let message = 'Message Delivered To: ' + event.receiver + event.body
              this.$toast.success(message);
              // Read unread messages - counter
              this.fetchMessages('unread')
              // Refresh unread messages
              this.fetchPaginatedItems()
            })
           .notification((notification) => {
              alert('yeey')
              console.log(notification.type);
            })
        } else {
          // console.log('NOT CALLED !!!!')
        }
      }
  }
}

</script>

<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon"/>
      </a>
      <!-- TODO: add search option - maybe search projects -->
      <!-- <div class="navbar-item">
        <div class="control">
          <input class="input" placeholder="Search everywhere...">
        </div>
      </div> -->
    </div>

    <div class="navbar-brand is-right">
      <a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuNavBarToggle">
        <b-icon :icon="menuNavBarToggleIcon" :custom-size="customIconSize()" />
      </a>
    </div>
    <!-- <router-link class="navbar-item button is-small is-primary is-right"
      v-if="isLoggedUser.roles.includes('participant')"
      :to="{
        name: 'users.edit',
        params: { slug: isLoggedUser.slug },
      }"
    >
      Please fill out all the personal info
    </router-link> -->

    <!-- <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
        <nav-bar-menu class="has-divider has-user-avatar">
          <div class="is-user-name">
            <span>{{ isLoggedUser.userProperName }} ({{ isLoggedUser.roles}})</span>
          </div>

          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item" href="/profile">
              <b-icon icon="account" :custom-size="customIconSize()"/>
              <span>My Profile</span>
            </a>
            <a class="navbar-item" style="pointer-events: none; opacity: .65;" href="/settings">
              <b-icon icon="settings" :custom-size="customIconSize()"/>
              <span>Settings</span>
            </a>
            <a class="navbar-item"  style="pointer-events: none; opacity: .65;" href="/mesages">
              <b-icon icon="email" custom-size="default"/>
              <span>Messages</span>
            </a>
            <hr class="navbar-divider">
            <a class="navbar-item" @click="logout">
              <b-icon icon="logout" :custom-size="customIconSize()"/>
              <span>Log Out</span>
            </a>
          </div>
        </nav-bar-menu>

        <a href="/" class="navbar-item has-divider is-desktop-icon-only" style="pointer-events: none; opacity: .65;" title="Help">
          <b-icon icon="help-circle-outline" :custom-size="customIconSize()" />
          <span>Help</span>
        </a>

        <a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout">
          <b-icon icon="logout" :custom-size="customIconSize()" />
          <span>Log out</span>
        </a>
      </div> -->
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import NavBarMenu from '@/components/layout/NavBarMenu'

export default {
  name: 'NavBar',
  components: {
    NavBarMenu
  },
  data () {
    return {
      isMenuNavBarActive: false
    }
  },
  computed: {
    ...mapGetters([
      // 'isLoggedIn',
      'isLoggedUser'
    ]),
    menuNavBarToggleIcon () {
      return (this.isMenuNavBarActive) ? 'close' : 'dots-vertical'
    },
    menuToggleMobileIcon () {
      return this.isAsideMobileExpanded ? 'backburger' : 'forwardburger'
    },
    ...mapState([
      'isNavBarVisible',
      'isAsideMobileExpanded'
    ])
  },
  methods: {
    /*...mapActions([
      'signUserOut'
    ]),*/
    menuToggleMobile () {
      this.$store.commit('asideMobileStateToggle')
    },
    menuNavBarToggle () {
      this.isMenuNavBarActive = (!this.isMenuNavBarActive)
    },
    customIconSize (iconSize) {
      let size = 'default';
      if(typeof iconSize !== 'undefined') {
        size = iconSize
      }
      return size
    },
    /*logout () {
      this.signUserOut()
        .then(() => {
          this.$router.push('/')
        })
        .catch((error) => {
          console.log('catch error:', error.message)
        })
    }*/
  }
}
</script>

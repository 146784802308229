import axios from 'axios';
import { SET_ERROR, CLEAR_ERROR, SET_LOADING } from '@/store/mutation-types'

/**
 * States
 */
const state = {
  paginate: false,
  pagination: {},
}

/**
 * Mutations
 */
const mutations = {
  setPaginate(state, payload) {
    state.paginate = true
  },

  setPagination(state, payload) {
    // payload.delete(data);
    // state.pagination = payload;
    state.pagination = {
      current_page: payload.current_page,
      first_page_url: payload.first_page_url,
      last_page_url: payload.last_page_url,
      prev_page_url: payload.prev_page_url,
      next_page_url: payload.next_page_url,
      path: payload.path,
      from: payload.from,
      to: payload.to,
      total: payload.total,
      per_page: payload.per_page,
      last_page: payload.last_page,
    }
  },

  setPaginationCurrentPage(state, payload) {
    state.pagination.current_page = payload
  },
}

/**
 * Getters
 */
const getters = {
  paginate: state => state.paginate,
  pagination: state => state.pagination,
}

/**
 * Actions
 */
const actions = {
  setPaginationCurrentPage({commit}, payload) {
    commit('setPaginationCurrentPage', payload);
  },
}


export default {
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'

import {
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SET_ITEM,
  SET_ITEMS
} from '@/store/mutation-types'

import {
} from '@/api-routes.js'

/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  item: null,
  items: null,
}

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  [SET_ITEMS] (state, payload) {
    state.items = payload
  },

  [SET_ITEM] (state, payload) {
    state.item = payload
  },

  /**
   * remove an item from frontend table after deleting item
   */
  removeItem(state, index) {
    // console.log('removeItem: ', index);
    state.items.splice(index, 1);
  },

  /**
   * update an item from frontend table after update(save item)
   */
  updateItem(state, payload) {
    // console.log('updateItem: ', state.items, payload.value);
    let item = state.items[payload.index];

    Object.assign(item, payload.value);
  }
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  items: state => state.items,
  item: state => state.item,
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  /**
   * fetch all items
   */
  fetchItems ({ commit, getters }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)
    commit(SET_ITEMS, null)

    return axios
      .get(payload)
      .then(response => {
        commit(SET_LOADING, false)
        // items are paginated, thus response data are more nested then for
        // one item
        // on one item response has the structure response, with data, config, headers, status,..
        // on many items: response has data (with data), status, config, ...
        // console.log('paginated items response: ', response.data.paginated)
        // let items = null;
        // Extend response items, items = array
        let items = response.data.data.data
        let pages = response.data.data
        if(typeof(items) === "undefined") {
          items = response.data.data;
          pages = response.data
        }
        // console.log('2: ', Array.isArray(response.data.data))
        // console.log('3: ', Array.isArray(response.data.data.data))
        // console.log('items: ', items, items.length);
        if (
          items === undefined ||
          items.length === 0
        ) {
          commit(SET_ERROR, 'items not found')
        } else {
          commit(SET_ITEMS, items)
          // console.log(response.data, response.data.data.current_page, response.data.data.total, response.data.data.last_page)
          // pagination is one level up
          commit("setPagination", pages);
        }
        return items;
      })
      .catch(error => {
        console.log('STORE error fetching items: ', error)
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data.errors.error);
      })
  },

  /**
   * fetch items on search, ie filtering --- check it
   */
  liveSearch({ commit }, payload) {
    commit(SET_ITEMS, null)
    let data = {
      field: payload.field,
      keyword: payload.value
    };

    axios
      .post(payload.endpoint + "/searchByField", data)
      .then(response => {
        // not paginated search results
        let data = response.data;
        // paginated
        // let data = response.data.data;
        commit(SET_ITEMS, data);
      })
      .catch(error => {
        if (error.hasOwnProperty("response")) {
          commit(SET_ERROR, error.response.data.message);
        } else {
          commit(SET_ERROR, "general error: " + error);
        }
        // console.log('error search: ', error.response.data.message, this.getters.error);
      });
  },

  /**
   * fetch specific item
   */
  fetchItem({ commit }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR);
    commit(SET_ITEM, null)
    // console.log('fetchItem endpoint: ', payload.endpoint)
    return axios
      .get(payload.endpoint)
      .then(response => {
        let data = response.data;

        // console.log('item data: ', response, data);
        commit(SET_ITEM, data)
        commit(SET_LOADING, false)
        return data
      })
      .catch(error => {
        // console.log('status: ', error.response.status)

        // if (isset(error.response.data.errors.error)) {
        if (typeof error.response !== 'undefined') {

          console.log('catch - YES')
          commit(SET_ERROR, error.response.data.errors.error)
        } else {
          console.log('catch - NO ', error.response)
          commit(SET_ERROR, error)
        }
        // console.log('STORE error fetching items: ', this.getters.error, error.response);
      });
  },

  /**
   * download an item
   */
  downloadItem({ commit }, payload) {
    commit(SET_LOADING, true);
    commit(CLEAR_ERROR);
    // console.log('downloadItem endpoint: ', payload.endpoint)
    axios({
      url: payload.endpoint,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        commit(SET_LOADING, false);
        let data = response.data;

        let filename
        let headers
        if(typeof payload.slug !== 'undefined') {
          headers = {'Accept': 'application/octet-stream'}
          // { 'Accept': 'application/vnd.ms-excel' } ??
          filename = payload.slug + '.csv'
        } else {
          headers = {type: 'application/pdf'}
          filename = response.statusText
        }
        // console.log('downloaded data: ', response, 'status txt: ', response.statusText, ' data: ', data);
        console.log('headers', headers)
        const url = window.URL.createObjectURL(new Blob([data], headers));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        commit(SET_ERROR, error.response);
        // console.log('STORE error fetching items: ', this.getters.error, error.response);
      });
  },

  /**
   * download all items
   */
  downloadItems({ commit }, payload) {
    commit(SET_LOADING, true);
    commit(CLEAR_ERROR);
    // console.log('downloadItems endpoint: ', payload.endpoint)
    axios({
      url: payload.endpoint,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        commit(SET_LOADING, false);
        let data = response.data;

        // console.log('downloaded data: ', response, 'status txt: ', response.statusText, ' data: ', data);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reportings.zip');
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        commit(SET_ERROR, error.response);
        // console.log('STORE error fetching items: ', this.getters.error, error.response);
      });
  },

  /**
   * save new item or update existing one
   */
  saveItem({ commit }, payload) {
    // console.log('this is save method: ', payload);
    return new Promise((resolve, reject) => {
      // let headers = null
      let headers = {'Content-Type': 'application/json'}
      if(payload.form === 'multipart') {
        let headers = {'Content-Type': 'multipart/form-data'}
      }
      axios[payload.method](payload.endpoint, payload.data, {headers: headers})
        .then(response => {
          // commit(CLEAR_ERROR);
          let data = response.data.data
          if(response.data === null || response.data.success === 0) {
            reject(data)
          }
          // console.log('data on save: ', response.data);
          resolve(data);
        })
        .catch(error => {
          if(typeof(error.response) !== 'undefined') {
            console.log('error response: ', error.response);
          } else {
            console.log("failure data: ", error)
          }
          // commit(SET_ERROR, 'Please fill missing fields');
          // commit(SET_LOADING, false);
          reject(error.response)
        });
    });
  },

  checkUnique({ commit }, payload) {
    if(payload.data.value === '') {
      commit(SET_ERROR, 'Field is required');
    }
    return new Promise((resolve, reject) => {
      axios.post(payload.endpoint, payload.data)
        .then(response => {
          let data = {}
          // console.log('check unique: ' ,  response.status);
          if (response.status === 204) {
            // console.log('ERROR: ', response, payload.data.type + ' already exists')
            commit(SET_ERROR, payload.data.type + ' already exists')
            data.message = payload.data.type + ' already exists'
            // data.responseClass = 'is-danger'
          } else {
            data.type = 'is-success'
            data.message = ''
            // console.log('status 200: ', response.status)
            commit(SET_ERROR, null)
          }
          resolve(data);
        })
        .catch(error => {
          // error.reponse props:  data, status, status text
          // (from Laravel: Unprocessable Entity)
          // other: headers, config, request..
          // console.log('ERR status: ', error.response)
          let errorResponse = '';
          if(error.response.status === 422) {
            errorResponse = error.response.data.errors.value[0]
            // console.log('status error 422, on empty error: ', error.response.data.errors.value[0] )
          } else {
            // console.log('not 422 error: ', error)
            errorResponse = error.response
          }
          commit(SET_ERROR, errorResponse)
          reject(errorResponse)
        });
    })
  },

  deleteItem({ commit }, payload) {
    // console.log('payload-delete: ', payload)
    return new Promise((resolve, reject) => {
      axios
        .delete(payload.endpoint)
        .then(response => {
          resolve(true)
          // console.log('res: ', response.data.record)
          if(payload.action === 'destroy') {
            commit("removeItem", payload.index)
          } else {
            let obj = {
              index: payload.index,
              value: response.data.record
            }
            // update values
            commit("updateItem", obj)
          }
        })
        .catch(error => {
          if(typeof(error.response) !== 'undefined') {
            console.log('error response: ', error.response)
            reject(error.response)
          } else {
            console.log("failure data: ", error)
            reject(error)
          }
        })
    })
  },

  setItems({ commit }, payload) {
    commit(SET_ITEMS, payload)
  },

  // not  in use
  uploadFile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // console.log('payload data: ', payload.data)

      let headers = {'Content-Type': 'multipart/form-data'}
      axios
        // .post(payload.endpoint, payload.data)
        .post(payload.endpoint, payload.data, { headers: headers })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          if(typeof(error.response) !== 'undefined') {
            console.log('error response: ', error.response.statusText + ' empty data')
            reject(error.response)
          } else {
            console.log("failure data: ", error.response.error)
            reject(error.response.data);
          }
        })
    })
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}

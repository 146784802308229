import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '@/store'
import { loginPage, loggedInPage, unauthorized } from '@/api-routes'
import i18n from '@/i18n.js'
import defineAbilitiesFor from '@/abilityFunc.js'

Vue.use(Router)

let router = new Router({
  base: process.env.BASE_URL,
  routes,
  mode: 'history',
  history: true,
  linkActiveClass: 'is-active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// console.log(router)
// to, from, next
router.beforeEach((route, redirect, next) => {
  if(typeof route.matched === 'undefined' || route.matched.length === 0) {
    console.log('HERE', route.matched, redirect)
  }
  // Set title dynamically
  if (route.meta.title !== undefined) {
    document.title = route.meta.title(route)
  }

  // locale setup
  if (store.state.language.language && store.state.language.language !== i18n.locale) {
    // console.log('i18n lang not en, on reload: ', store.state.language.language, Vue.locale)
    i18n.locale = store.state.language.language
    // Vue.locale = store.state.language.language
    next()
  } else if (!store.state.language.language) {
    store.dispatch('setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.state.language.language
        next()
      })
  } else {
    next()
  }
  // Auth guard
  if (route.matched.some(record => record.meta.forVisitors)) {
    // console.log('you are logged out - display links for login/register: ', store.state.userStore)

    if (store.state.userStore.isLoggedIn) {
      // console.log('logged in-routes - logged in')
      next({
        name: loggedInPage
      })
    } else next()
  } else if (route.matched.some(record => record.meta.forAuth)) {
    // console.log('should be logged IN: ', store.state.userStore)
    if (!store.state.userStore.isLoggedIn) {
      // console.log('login in-routes - not logged in')
      next({
        name: loginPage
      })
    } else {
      // For reload page we need to define restriction
      let ability
      if (typeof localStorage.getItem("permissions") === 'string') {
        ability = defineAbilitiesFor(localStorage.getItem("permissions").split(','))
      }
      // console.log('router ability-on reload: ', ability)
      // Vue.use(abilitiesPlugin, ability)
      // console.log('before each logged in', store.state.userStore.ability.rules, this.$ability)
      // check permissions here:
      // if route has no associated resource or user is admin: let them pass
      // TODO: checking if a user is an admin should take place in our permissions module
      // else check permissions:
      // console.log(route.meta.hasOwnProperty('resource'), route.meta.hasOwnProperty('action'))

      //fix invitations reject-accept, if error-display success msg for demo- UNDO after demo!
      if (
        // (!route.meta.hasOwnProperty('resource') || route.meta.resource !== null)||
        !route.meta.hasOwnProperty('resource') ||
        store.getters.isAdmin ||
        ability.can(route.meta.action || 'read', route.meta.resource) ||
        ability.can('list', route.meta.resource) ||
        ability.can('update', route.meta.resource) ||
        ability.can('create', route.meta.resource) ||
        ability.can('delete', route.meta.resource) ||
        ability.can('all', route.meta.resource)) {
        // on clic data available, otherwise not
        // console.log('allowed? ', ability, store.state.userStore.ability, route.meta.resource, ability.can('list', route.meta.resource))
        next()
      }

      else {
        // on reload - ability.rules were empty
        // moved updateAbility to main.js fixed that
        // console.log('allowed? ', route.meta.resource, ability.can('list', route.meta.resource), route, ability.rules)

        next({
          name: unauthorized
        })
      }
    }
  } else {
    // alert('not handled exception - route params missing, or')
    if(route.name === null) {
      console.log('TODO !!! - not handled -  ie when required params are completely missing, we would need landing page!!!', route)
      next({
        // name: unauthorized
      })
    }
    next()
  }

  next()
})

export default router

<template>
  <b-button class="inbox button is-ghost" href="#">
    <b-dropdown
      append-to-body
      aria-role="menu"
      scrollable
      max-height="200"
      trap-focus
    >
      <template #trigger>
        <a class="menu-item" role="button">
          <span :title="$getTranslation('messaging.unread') + ' ' + $getTranslation('messaging.message', 1, true)" class="badge is-danger" v-if="messages && messages.length > 0">{{ allMessagesCount }}</span>
          <b-icon icon="mdi mdi-bell" />
        </a>
      </template>

      <b-dropdown-item
        v-for="item of messages"
        :key="item.id"
        aria-role="listitem"
        >
        <router-link
          :to="'/messages/' + item.slug + '/view'"
          @click.native="updateCount"
        >
          {{ item.sender.name }} {{ item.subject }}
        </router-link>
      </b-dropdown-item>

      <b-dropdown-item>
        <a href="/messages">{{ $getTranslation('general.show-all') }}</a>
      </b-dropdown-item>
    </b-dropdown>
  </b-button>
</template>

<script>
import Echo from "laravel-echo"
import { mapGetters, mapActions } from 'vuex'

export default {
  props: [],

  data() {
    return {
      //
    }
  },

  computed: {
    ...mapGetters([
      'messages',
      'allMessagesCount'
    ]),
  },


  methods: {
    ...mapActions([
      'fetchMessages'
    ]),
    updateCount () {
      this.fetchMessages('unread')
      console.log(this.allMessagesCount)
    }
  },

  created() {
    this.fetchMessages('unread')
  },
}

</script>

import general from './json/de/general.json'
import form from './json/de/form.json'
import auth from './json/de/auth.json'
import profile from './json/de/profile.json'
import surveys from './json/de/surveys.json'
import invitation from './json/de/invitation.json'
import messaging from './json/de/messaging.json'
import menu from './json/de/menu.json'

export default {
  general,
  form,
  auth,
  profile,
  surveys,
  invitation,
  messaging,
  menu
};
import axios from 'axios'

import {
  SET_ORGANIZATIONS,
  SET_ORGANIZATION,
  SET_ORGANIZATION_DIVISIONS,
  SET_ORGANIZATION_USERS,
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR
} from '@/store/mutation-types'

import {
// ApiOrganizations,
// ApiOrganization
} from '@/api-routes.js'

/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  organizations: null,
  organization: null,
  organizationUsers: null,
  organizationDivisions: null
}

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  [SET_ORGANIZATIONS] (state, payload) {
    state.organizations = payload
  },

  [SET_ORGANIZATION] (state, payload) {
    state.organization = payload
  },

  [SET_ORGANIZATION_DIVISIONS] (state, payload) {
    state.organizationDivisions = payload
  },

  [SET_ORGANIZATION_USERS] (state, payload) {
    state.organizationUsers = payload
  }
}

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  organizations: state => state.organizations,
  organization: state => state.organization,
  organizationUsers: state => state.organizationUsers,
  organizationDivisions: state => state.organizationDivisions
}

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  /**
   * fetch all organizations
   *
   * fetch all organizations by user
   */
  fetchOrganizations ({ commit, getters }, payload) {
    // console.log("fetch organizations time: ", Date.now(), payload);
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    axios
      .get(payload)
      .then(response => {
        commit(SET_LOADING, false)
        // with old response - json
        // let organizations = response.data.data;
        // new - using Api resources
        let organizations = response.data.data.data
        // console.log('organizations: ', organizations);
        if (
          organizations === undefined ||
          organizations.length === 0
        ) {
          // console.log('empty organizations');
          commit(SET_ERROR, 'organizations not found')
        } else {
          commit(SET_ORGANIZATIONS, organizations)
          // if (response.data.hasOwnProperty("users")) {
          /* if (
            users === undefined ||
            users.length === 0
          ) {
            console.log('users: ', response.data.users);
            commit(SET_ORGANIZATION_USERS, users);
            // commit(SET_ORGANIZATION_USERS, response.data.users);
          } */
        }
        // document.title = response.data.title;
      })
      .catch(error => {
        console.log('STORE error fetching organizations: ', error)
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data.errors.error);
      })
  },

  fetchOrganizationsName ({ commit, getters }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)
    axios
      .get(payload)
      .then(response => {
        commit(SET_LOADING, false)
        let organizations = response.data.data
        if (
          organizations === undefined ||
          organizations.length === 0
        ) {
          commit(SET_ERROR, 'organizations not found')
        } else {
          commit(SET_ORGANIZATIONS, organizations)
          // console.log('organizations name: ', organizations)
        }
      })
      .catch(error => {
        // console.log('STORE error fetching organizations: ', error)
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data.errors.error)
      })
  },

  fetchOrganizationDivisions ({ commit, getters }, payload) {
    commit(SET_LOADING, true)
    commit(CLEAR_ERROR)

    axios
      .get(payload)
      .then(response => {
        commit(SET_LOADING, false)
        let divisions = response.data.data
        if (
          divisions === undefined ||
          divisions.length === 0
        ) {
          commit(SET_ERROR, 'divisions not found')
        } else {
          commit(SET_ORGANIZATION_DIVISIONS, divisions)
          // console.log('organization divisions: ', divisions)
        }
      })
      .catch(error => {
        // console.log('STORE error fetching organizations: ', error)
        commit(SET_LOADING, false)
        // commit(SET_ERROR, error.response.data.errors.error)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

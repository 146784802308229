<template>
    <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large"/>
            </p>
            <p>{{ $getTranslation('general.fetch') }}</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large"/>
            </p>
            <p>{{ $getTranslation('general.0records') }}</p>
          </template>
        </div>
      </section>
</template>

<script>
export default {
  name: 'app-section-empty',
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    //
  },
  methods: {
    //
  },

  created () {
    // console.log('loaded section empty ', this.isLoading)
  }
}
</script>
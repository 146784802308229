import axios from 'axios';

import {
  SET_LOADING,
  SET_ERROR,
  CLEAR_ERROR,
  SET_MESSAGE,
  SET_MESSAGES,
  SET_MESSAGES_COUNT
} from '@/store/mutation-types';

import { ApiMessaging } from '@/api-routes.js'
/**
 * States
 * contain all your application level state
 *
 * @type {Object}
 */
const state = {
  message: null,
  messages: null,
  allMessagesCount: 0
};

/**
 * Mutations
 * the only way to chage the state of a store, like events
 * Modify the states
 * sync functions used to update the state
 * commit()  invoke a mutation to actually update the data in the store
 */
const mutations = {
  [SET_MESSAGE](state, payload) {
    // console.log(' set message: ', payload);
      state.message = payload;
  },

  [SET_MESSAGES](state, payload) {
      // console.log('messages: ', payload.length)
      state.messages = payload
  },

  [SET_MESSAGES_COUNT](state, payload) {
      state.allMessagesCount = payload
  },
};

/**
 * Getters
 * provide derived state from the store
 * ie: grab computed data from the store
 * takes a state object and returns a value from it
 * @type {Object}
 */
const getters = {
  isMessage: state => state.message,
  messages: state => state.messages,
  allMessagesCount: state => state.messages.length,
  allMessages: state => state.messages,
};

/**
 * Actions
 * can autocontain async operations
 *
 * @type {Object}
 */
const actions = {
  // Send message
  create({ commit }, slug) {
    commit(SET_LOADING, true);
    commit(CLEAR_ERROR);

    axios.post(ApiMessaging )
      .then((response) => {
        commit(SET_LOADING, false);
        commit(SET_MESSAGE, response.data.status);
      }).catch((error) => {
        commit(SET_LOADING, false);
        commit(SET_ERROR, error);
        console.log('ERROR : ', error);
      });
  },

  // fetch unread, sent, received messages
  fetchMessages({ commit }, type) {
    // Warning if set_loading: You may have an infinite update loop in a component render function.
    // This method is running all the time
    // commit(SET_LOADING, true);
    commit(CLEAR_ERROR);
    let endpoint = ApiMessaging

    // type can be sent, received, unread
    let array = ['sent', 'received', 'unread']
    if (typeof type !== 'undefined' && array.indexOf(type) !== -1) {
      if (array.indexOf(type) !== -1) {
        endpoint += '/' + type
      }
    }

    axios.get(endpoint)
      .then((response) => {
        commit(SET_LOADING, false);
        let data = response.data.data.data;
        // console.log('response: ', response.data.data.data.length)
        commit(SET_MESSAGES, data);
        commit(SET_MESSAGES_COUNT, data.length);
        /*response.data.data.forEach((message) => {
          console.log(message)
          commit(ADD_USER_NOTIFICATION, message);
        })*/
        // console.log('unreadNotifications: ', data, this.getters.allMessagesCount);
      }).catch((error) => {
        // commit(SET_LOADING, false);
        commit(SET_ERROR, error.response);
        console.log('ERROR : ', error);
    });
  },

  // Get all messages
  /*messages({ commit }) {
    commit(SET_LOADING, true);
    commit(CLEAR_ERROR);
    // console.log('slug: ', slug);

    axios.get(ApiMessaging )
      .then((response) => {
        commit(SET_LOADING, false);
        commit(SET_MESSAGES, response.data);
        // response.data.messages.forEach((messages) => {
        //   commit('send message', messages);
        // })
        // console.log('messages: ', response);
      }).catch((error) => {
        commit(SET_LOADING, false);
        commit(SET_ERROR, error.response);
        console.log('ERROR : ', error);
      });
  },*/

  /*delete({ commit }, payload) {
    commit(SET_LOADING, true);
    commit(CLEAR_ERROR);
    console.log('payload: ', payload, payload.requester);

    axios.delete(ApiMessaging + '/delete/'+ payload.id)
      .then((response) => {
        commit(SET_LOADING, false);
        console.log('messages: ', response);
      }).catch((error) => {
        commit(SET_LOADING, false);
        commit(SET_ERROR, error.response);
        console.log('ERROR : ', error);
      });
  }*/
};

export default {
  state,
  getters,
  mutations,
  actions
};

<template>
  <footer v-show="isFooterBarVisible" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left" v-if="isLoggedIn">
          <router-link v-for="item in footerMenu" :key="item.title" :to="item.link" exact>
                <b-icon v-if="item.icon" :icon="item.icon" :class="{ 'has-update-mark' : item.updateMark }" custom-size="default"  />
                {{ item.title }}
            </router-link>
        </div>
        <div class="level-left" v-else>

        </div>
        <div class="level-right">
          <language-picker class="navbar-item has-divider" />
          <div class="level-item">
            <div class="level-item">
              &copy; {{ getCopyright }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import LanguagePicker from '../internationalization/LanguagePicker'

export default {
  name: 'FooterBar',
  components: { LanguagePicker },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ]),
    ...mapState([
      'isFooterBarVisible'
    ]),
    year () {
      return moment().year()
    },
    getCopyright () {
      return 'metrify.co - ' + (new Date()).getFullYear()
    },
    footerMenu () {
      return [
        { icon: 'desktop-mac', title: 'Dashboard', link: '/dashboard' },
        { icon: 'account-circle', title: 'Profile', link: '/profile' },
        { icon: 'view-list', title: 'Surveys', link: '/surveys' }
      ]
    }
  }
}
</script>
